import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import Pricing from '../components/Pricing'
import Layout from '../components/Layout'
import Features from '../components/Features'

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  seo,
  mainpitch,
  intro,
  offers,
  preview = false
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        className="introduction"
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
        >
          {title}
        </h1>
        <h3
          className="is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="content">
                    <ReactMarkdown source={mainpitch.description} />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {intro.heading}
                    </h3>
                    <ReactMarkdown source={intro.description} />
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
                <div className="columns">
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/services">
                      See all services
                    </Link>
                  </div>
                </div>
                <div className="columns" style={{ marginTop: 64 }}>
                  <div className="column is-12">
                    <h2 className="has-text-weight-semibold is-size-2 has-text-centered	">
                      {offers.heading}
                    </h2>
                    <p className='has-text-centered	'>{offers.description}</p>
                    <Pricing offers data={offers.services} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style={!preview ? {
      position: 'fixed',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      overflow: 'hidden'
    } : {}}>
      <h1>{seo.heading}</h1>
      <ReactMarkdown source={seo.description} />
      {seo.image1.image.childImageSharp.fluid.src && (
        <img src={seo.image1.image.childImageSharp.fluid.src} alt={seo.image1.alt}/>
      )}
      {seo.image2.image.childImageSharp.fluid.src && (
        <img src={seo.image2.image.childImageSharp.fluid.src} alt={seo.image2.alt}/>
      )}
      {seo.image3.image.childImageSharp.fluid.src && (
        <img src={seo.image3.image.childImageSharp.fluid.src} alt={seo.image3.alt}/>
      )}

      {seo.links.map(({ link, text}, index) => {
        return (<a key={`seo.link.${index}`} target='_blank' href={link}>{text}</a>)
      })}
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  seo: PropTypes.object,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  offers: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    services: PropTypes.array,
  })
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        seo={frontmatter.seo}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        offers={frontmatter.offers}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch {
          title
          description
        }
        seo {
          heading,
          description,
          links {
            link
            text
          }
          image1 {
            image {
              childImageSharp {
                fluid(maxWidth: 84, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          },
          image2 {
            image {
              childImageSharp {
                fluid(maxWidth: 84, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          },
          image3 {
            image {
              childImageSharp {
                fluid(maxWidth: 84, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        offers {
          heading
          description
          services {
            description
            id
            service
            price
          }
        }
      }
    }
  }
`
